import { Navigation } from '@/components/Navigation'
import { StaticImage } from 'gatsby-plugin-image'
import leaf from '@/images/leaf-footer.svg'
import { Contact } from '@/components/Contact'
import { MinimalFooter } from '@/components/MinimalFooter'
import { SEO } from '@/components/Seo'
import { HeadProps } from 'gatsby'

export const Head = (props: HeadProps) => (
  <SEO
    title="Luxury Wedding Flowers | Bespoke Floral Designs"
    pathname={props.location.pathname}
    description="Flowers by Primrose can help you put together a floral arrangement for the special day"
  />
)

export default function Weddings() {
  return (
    <div className="relative overflow-hidden bg-white">
      <header className="relative bg-background overflow-hidden">
        <div className="mx-auto max-w-7xl">
          <div className="relative z-10 bg-background pb-8 sm:pb-16 md:pb-20 lg:w-full lg:max-w-2xl lg:pb-28 xl:pb-48">
            <svg
              className="absolute inset-y-0 right-0 hidden h-full w-48 translate-x-1/2 transform text-background lg:block"
              fill="currentColor"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              aria-hidden="true"
            >
              <polygon points="50,0 100,0 50,100 0,100" />
            </svg>

            <Navigation />

            <div className="mx-auto mt-10 max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <div className="sm:text-center lg:text-left">
                <h1 className="mt-4 font-serif text-4xl tracking-tight text-gray-900 sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                  <span className="block">Flowers for the big day</span>
                </h1>
                <p className="mt-3 font-light tracking-tight text-base text-gray-900 sm:mx-auto sm:mt-5 sm:max-w-xl sm:text-lg md:mt-5 md:text-md lg:mx-0 lg:w-3/4">
                  Flowers by Primrose is here to make your wedding day that
                  extra bit special, by creating beautiful and romantic floral
                  designs just for you.
                </p>
                <div className="mt-8">
                  <a
                    href="#get-in-touch"
                    className="inline-block rounded-md border border-transparent bg-phathloGreen py-3 px-8 font-bold uppercase tracking-widest text-white hover:ring-2 hover:ring-offset-2 hover:ring-phathloGreen text-xs"
                  >
                    Get in touch
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <StaticImage
            className="h-56 w-full object-cover sm:h-72 md:h-96 lg:h-full lg:w-full"
            src="../images/wedding-hero.jpg"
            alt=""
          />
        </div>
      </header>
      <main>
        <Info />
        <Gallery />
        <Contact />
      </main>

      <MinimalFooter />
    </div>
  )
}

const Info = () => (
  <div className="bg-white">
    <div className="mx-auto max-w-7xl py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
      <div className="text-center">
        <img className="h-16 object-contain mx-auto" src={leaf} alt="" />
        <h2 className="font-serif text-4xl tracking-tight text-gray-900 sm:text-5xl lg:text-6xl">
          Weddings
        </h2>
        <div className="mx-auto mt-8 max-w-xl font-light text-base text-gray-900 leading-relaxed space-y-6">
          <p>
            Flowers by Primrose can help make your floral dreams come true for
            your wedding day, whether it's a simple and stunning vision you have
            or a grand and lavish one.
          </p>
          <p>
            After a consultation (virtual or in person) to understand your
            vision for your wedding, you will receive a quotation. Primrose will
            choose sensational and seasonal flowers to bring your imagination to
            life.
          </p>
          <p>
            No wedding is too small or too big, and Primrose will work with you
            to help you create something beautiful within your budget. Contact
            Primrose to discuss your bespoke wedding plans.
          </p>
        </div>
      </div>
    </div>
  </div>
)

const Gallery = () => (
  <div className="bg-background">
    <div className="mx-auto max-w-7xl py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
      <div className="text-center">
        <h2 className="font-serif text-4xl tracking-tight text-gray-900 sm:text-5xl lg:text-6xl mb-16">
          Gallery
        </h2>

        <div className="w-full grid grid-cols-1 gap-y-4 lg:gap-y-8 lg:grid-cols-3 lg:gap-x-8 mx-auto">
          <div className="lg:h-[500px]">
            <StaticImage
              className="h-full object-cover"
              src="../images/wedding1.png"
              alt=""
            />
          </div>
          <div className="lg:h-[500px]">
            <StaticImage
              className="h-full object-cover"
              src="../images/wedding-gallery-1.jpg"
              alt=""
            />
          </div>

          <div className="lg:h-[500px]">
            <StaticImage
              className="h-full object-cover"
              src="../images/wedding3.png"
              alt=""
            />
          </div>
          <div className="lg:h-[500px] ">
            <StaticImage
              className="h-full object-cover"
              src="../images/wedding2.png"
              alt=""
            />
          </div>
          <div className="lg:h-[500px]">
            <StaticImage
              className="h-full object-cover"
              src="../images/wedding-gallery-3.jpg"
              alt=""
            />
          </div>
          <div className="lg:h-[500px]">
            <StaticImage
              className="h-full object-cover"
              src="../images/wedding-gallery-2.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
)
